import { Component, OnInit, Input, Output, EventEmitter,ElementRef,ViewChild, NgZone  } from "@angular/core";
import { ServerApiService } from "app/services/server-api.service";
import { forkJoin, Subscription } from "rxjs";
import { finalize,map }  from 'rxjs/operators';
import { ModalService } from "../modal/modal.service";
import { StudentDataService } from "app/services/students/student-data.service";
import  {HttpEventType,HttpClient,HttpEvent } from '@angular/common/http'

@Component({
  selector: "app-upload-files",
  templateUrl: "./upload-files.component.html",
  styleUrls: ["./upload-files.component.css"],
  providers: [ServerApiService],
})
export class UploadFilesComponent implements OnInit {
  @Input() courseInfo;
  @Input() role;
  @Input() path;
  @Input() id;
  uploadPointer;
  @Output() fileUploaded: EventEmitter<any>;
  @ViewChild('myDialog', { static: true }) myDialog!: ElementRef;

  isUploadGif: boolean = false
  modalWidth: number;
  x: number;
  y: number;
  studentId: string
  fileName:string = ''
  uploadProgress:number| null = null;
  uploadSub: Subscription;
  uploadFileName:string|null = null

  constructor(
    private serverApiService: ServerApiService,
    private modalService: ModalService,
    private studentDataService: StudentDataService,
    private http: HttpClient,
    private ngZone: NgZone
  ) {
    this.fileUploaded = new EventEmitter();
    this.modalWidth = Math.floor(document.body.offsetWidth * 0.4);
    this.x = Math.floor(document.body.offsetWidth * 0.35);
    this.y = Math.floor(document.body.offsetHeight * 0.35);
  }

  ngOnInit(): void {
    this.studentId = this.studentDataService.getStudentInfo().studentID; 
  }
  openModal(id: string, x: number, y: number, l: number) {
    this.modalService.open(id, x, y, l);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }
  funckyTown($event) {
    console.log(this.role);
    if (this.role === "homework" || this.role === "classwork") {
      this.uploadFiles();
    }
    if (this.role === "classworkCycle") {
      this.uploadClassWorkForCycle();
    }
    if (this.role === "homeworkStudents") {
      this.uploadHomeWorkStudent();
    }
  }
  handleFileInput(input: HTMLInputElement) {
    console.log('handleFileInput:',input, input.files);
    if (input.files && input.files.length > 0) {
      this.uploadPointer = Array.from(input.files); // Process the selected files
      console.log('Files selected:', this.uploadPointer);
      
      // Perform any additional processing you need here...
      
      // Reset the input field so the same file can be selected again
      input.value = '';
    }
  }
  getFileNameList() {
    if (!this.uploadPointer) {
      return "Choose files";
    }
    let thing = "";
    const size = this.uploadPointer.length;
    for (let index = 0; index < size; index++) {
      thing +=
        index < size - 1
          ? this.uploadPointer[index].name + "\n"
          : this.uploadPointer[index].name;
    }
    return thing;
  }
  uploadFiles() {
    console.log(this.path);
    const track = !this.path.shared ? this.path.paths[0] : "shared";
    const batch = [];
  
    if (!this.uploadPointer) {
      this.openModal("no-file-error", this.x, this.y, this.modalWidth);
      return;
    }
  
    this.uploadProgress = 0;
    let totalFiles = Object.keys(this.uploadPointer).length;
    let filesUploaded = 0;
  
    Object.keys(this.uploadPointer).forEach((key) => {
      const upload$ = this.serverApiService.uploadFiles(this.uploadPointer[key], this.role, {
        track: track,
        course: this.courseInfo.courseName,
        lesson: this.courseInfo.sessionNum,
      }).pipe(
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = event.total ? Math.round(100 * (event.loaded! / event.total!)) : 0;
            this.ngZone.run(() => {
              this.uploadProgress = Math.round((progress + filesUploaded * 100) / totalFiles);
            });
          } else if (event.type === HttpEventType.Response) {
            filesUploaded++;
          }
          return event;
        }),
        finalize(() => {
          console.log('finalize1');
          
          if (filesUploaded === totalFiles) {
            console.log('finalize2');
            this.isUploadGif = false;
            this.uploadPointer = null;
            this.closeModal("loading-file");
            this.myDialog.nativeElement.close();
            this.openModal("file-uploaded", 40, 20, 40);
            this.ngZone.run(() => {
              this.uploadProgress = null;  // Reset progress to null to hide the progress bar
            });
          }
        })
      );
  
      batch.push(upload$);
    });
  
   // this.myDialog.nativeElement.showModal();
    this.isUploadGif = true;
  
    this.uploadSub = forkJoin(batch).subscribe(
      (ans) => {
        this.fileUploaded.emit();
      },
      (err) => {
        this.closeModal("loading-file");
        console.log(err);
      }
    );
  }
  // uploadFiles() {
  //   const batch = [];
  //   const track = !this.path.shared ? this.path.paths[0] : "shared";
  //   console.log("the pointer", this.uploadPointer);
  //   if (!this.uploadPointer) {
  //     this.openModal("no-file-error", this.x, this.y, this.modalWidth);
  //     return;
  //   }
  //   Object.keys(this.uploadPointer).forEach((key) => {
  //     console.log('this.uploadPointer[key]',this.uploadPointer[key]);
      
  //     batch.push(
  //       this.serverApiService.uploadFiles(this.uploadPointer[key], this.role, {
  //         track: track,
  //         course: this.courseInfo.courseName,
  //         lesson: this.courseInfo.sessionNum,
  //       })
  //     );
  //   });
  //   // this.openModal("loading-file", this.x, this.y, this.modalWidth);
  //   //this.myDialog.nativeElement.showModal();
  //   console.log('this.isUploadGif = true');
    
  //   console.log('batch:',batch);
  //   forkJoin(batch).subscribe(
  //     (ans) => {
  //       this.fileUploaded.emit();
  //       this.uploadPointer = null;
  //       this.myDialog.nativeElement.close();
  //       console.log('this.isUploadGif = false');
  //       // this.closeModal("loading-file");
  //       this.openModal("file-uploaded", this.x, this.y, this.modalWidth);
  //     },
  //     (err) => {
  //       this.closeModal("loading-file");
  //       console.log(err)
  //     }
  //   );
  // }

 uploadClassWorkForCycle() {
  console.log(this.path);
  const track = !this.path.shared ? this.path.paths[0] : "shared";
  const batch = [];
  if (!this.uploadPointer) {
    this.openModal("no-file-error", this.x, this.y, this.modalWidth);
    return;
  }
  this.uploadProgress = 0;
  let totalFiles = Object.keys(this.uploadPointer).length;
  let filesUploaded = 0;
  Object.keys(this.uploadPointer).forEach((key) => {
    const upload$ = this.serverApiService.uploadFiles(this.uploadPointer[key], this.role, {
      track: track,
      course: this.courseInfo.courseName,
      lesson: this.courseInfo.sessionNum,
      cycle: this.courseInfo.cyclecode,
    }).pipe(
      map((event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          const progress = event.total ? Math.round(100 * (event.loaded! / event.total!)) : 0;
          this.ngZone.run(() => {
            this.uploadProgress = Math.round((progress + filesUploaded * 100) / totalFiles);
          });
        } else if (event.type === HttpEventType.Response) {
          filesUploaded++;
        }
        return event;
      }),
      finalize(() => {
        console.log('finalize1');
        
        if (filesUploaded === totalFiles) {
          console.log('finalize2');
          this.isUploadGif = false;
          this.uploadPointer = null;
          this.closeModal("loading-file");
          this.myDialog.nativeElement.close();
          // this.openModal("file-uploaded", this.x, this.y, this.modalWidth);
          this.openModal("file-uploaded", 40, 20, 40);
          this.ngZone.run(() => {
            this.uploadProgress = null;  // Reset progress to null to hide the progress bar
          });
        }
      })
    );
    batch.push(upload$);
  });
 // this.myDialog.nativeElement.showModal();
 this.isUploadGif = true;

 this.uploadSub = forkJoin(batch).subscribe(
   (ans) => {
     this.fileUploaded.emit();
   },
   (err) => {
     this.closeModal("loading-file");
     console.log(err);
   }
 );
}

  // uploadClassWorkForCycle() {
  //   const track = !this.path.shared ? this.path.paths[0] : "shared";
  //   const batch = [];
  //   if (!this.uploadPointer) {
  //     this.openModal("no-file-error", this.x, this.y, this.modalWidth);
  //     return;
  //   }
  //   Object.keys(this.uploadPointer).forEach((key) => {
  //     batch.push(
  //       this.serverApiService.uploadFiles(this.uploadPointer[key], this.role, {
  //         track: track,
  //         course: this.courseInfo.courseName,
  //         lesson: this.courseInfo.sessionNum,
  //         cycle: this.courseInfo.cyclecode,
  //       })
  //     );
  //   });
  //   this.openModal("loading-file", this.x, this.y, this.modalWidth);
  //   forkJoin(batch).subscribe(
  //     (ans) => {
  //       this.fileUploaded.emit();
  //       this.uploadPointer = null;
  //       this.closeModal("loading-file");
  //       this.openModal("file-uploaded", this.x, this.y, this.modalWidth);
  //     },
  //     (err) => {
  //       this.closeModal("loading-file");
  //       console.log(err)
  //     }
  //   );
  // }




  uploadHomeWorkStudent() {
    console.log(this.path);
    const track = !this.path.shared ? this.path.paths[0] : "shared";
    const batch = [];
  
    if (!this.uploadPointer) {
      this.openModal("no-file-error", this.x, this.y, this.modalWidth);
      return;
    }
  
    this.uploadProgress = 0;
    let totalFiles = Object.keys(this.uploadPointer).length;
    let filesUploaded = 0;
  
    Object.keys(this.uploadPointer).forEach((key) => {
      const upload$ = this.serverApiService.uploadFiles(this.uploadPointer[key], this.role, {
        track: track,
        course: this.courseInfo.courseName,
        lesson: this.courseInfo.sessionNum,
        id: this.id,
      }).pipe(
        map((event: HttpEvent<any>) => {
          if (event.type === HttpEventType.UploadProgress) {
            const progress = event.total ? Math.round(100 * (event.loaded! / event.total!)) : 0;
            this.ngZone.run(() => {
              this.uploadProgress = Math.round((progress + filesUploaded * 100) / totalFiles);
            });
          } else if (event.type === HttpEventType.Response) {
            filesUploaded++;
          }
          return event;
        }),
        finalize(() => {
          console.log('finalize1');
          
          if (filesUploaded === totalFiles) {
            console.log('finalize2');
            this.isUploadGif = false;
            this.uploadPointer = null;
            this.closeModal("loading-file");
            this.myDialog.nativeElement.close();
            this.openModal("file-uploaded", 40, 20, 40);
            this.ngZone.run(() => {
              this.uploadProgress = null;  // Reset progress to null to hide the progress bar
            });
          }
        })
      );
  
      batch.push(upload$);
    });
  
   // this.myDialog.nativeElement.showModal();
    console.log('batch:', batch);
    this.isUploadGif = true;
  
    this.uploadSub = forkJoin(batch).subscribe(
      (ans) => {
        this.fileUploaded.emit();
      },
      (err) => {
        this.closeModal("loading-file");
        console.log(err);
      }
    );
  }
  
  

  
  cancelUpload() {
    if (this.uploadSub) {
      this.uploadSub.unsubscribe();
    }
    this.uploadPointer = null;  // Clear the uploadPointer
    this.uploadProgress = null; // Reset the progress
    this.isUploadGif = false;   // Reset the GIF flag if needed
    this.closeModal("loading-file"); // Close any modals if open
  }
  
  reset() {
    this.uploadProgress = null;
    this.uploadSub = null;
  }
  
  // uploadHomeWorkStudent() {
  //   console.log(this.path);
  //   const track = !this.path.shared ? this.path.paths[0] : "shared";
  //   const batch = [];
  //   if (!this.uploadPointer) {
  //     this.openModal("no-file-error", this.x, this.y, this.modalWidth);
  //     return;
  //   }
  //   Object.keys(this.uploadPointer).forEach((key) => {
  //     batch.push(
  //       this.serverApiService.uploadFiles(this.uploadPointer[key], this.role, {
  //         track: track,
  //         course: this.courseInfo.courseName,
  //         lesson: this.courseInfo.sessionNum,
  //         id: this.id,
  //       })
  //     );
  //   });
  //   // this.openModal("loading-file", this.x, this.y, this.modalWidth);
  //   this.myDialog.nativeElement.showModal();
  //   console.log('batch:',batch);
  //   this.isUploadGif = true
  //   forkJoin(batch).subscribe(
  //     (ans) => {
  //       this.fileUploaded.emit();
  //       this.uploadPointer = null;
  //       this.closeModal("loading-file");
  //       this.myDialog.nativeElement.close();
  //       this.isUploadGif = false
  //       this.openModal("file-uploaded", this.x, this.y, this.modalWidth);
  //     },
  //     (err) => {
  //       this.closeModal("loading-file");
  //       console.log(err)
  //     }
  //   );
  // }
}
