import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable,of  } from "rxjs";

/* model imports. */
import { Course } from "app/models/interfaces/course/course";
import { CycleStudents } from "app/models/interfaces/cycle/cycleStudent";
import { registeredCourse } from "app/models/interfaces/registeredCourse/registeredCourse";
import { VideoData } from "app/models/interfaces/videoList/videoList";
import { TeacherCycles } from "app/models/interfaces/cycle/cycleTeacher";
import { VisitsTotal } from "app/models/interfaces/visits/visitsTotal";
import { environment } from "environments/environment";
console.log('environment on service =======>',environment)

@Injectable()
export class ServerApiService {
  private baseUrl = `${environment.HOST}`;

  constructor(private http: HttpClient) {}

  //login //start
  //*********************************** */
  checkTokenValidity() {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get(`${this.baseUrl}/auth/redirect`, httpOptions);
  }

  loginWithToken() {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get(`${this.baseUrl}/user`, httpOptions);
  }

  fetchLoginNew(username: string, password: string) {
    const socket_id = localStorage.getItem("socketId");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    let user = {
      username: username,
      password: password,
      socket_id: socket_id

    };
    let data = JSON.stringify(user);
    return this.http.post(`${this.baseUrl}/login`, data, httpOptions);
  }
  //login //end
  //*********************************** */

  //logout
  logOut() {
    const socket_id = localStorage.getItem("socketId");
    localStorage.removeItem(socket_id)
    const httpOptions = {
      withCredentials: true,
    };

    return this.http.get(`${this.baseUrl}/logout?socket_id=${socket_id}`, httpOptions);
  }

  /* Teacher //start*/
  //*********************************** */
  /* requests */
  giveMeHostLink(meeting: number, host_acc: string, cycle: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      // withCredentials: true
      responseType: "text" as "json",
      withCredentials: true,
    };
    const body = {
      data: {
        meeting_id: meeting.toString(),
        usr: host_acc,
        cycle: cycle,
      },
    };
    return this.http.post(
      `${this.baseUrl}/teacher/unicko-host`,
      body,
      httpOptions
    );
  }

  postStudentVisits(visits: string) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true,
    };
    return this.http.post(
      `${this.baseUrl}/teacher/student_visits`,
      visits,
      httpOptions
    );
  }

  postCoursesPerStudent(courseperstudent: string) {
    console.log(courseperstudent);
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      withCredentials: true,
    };
    return this.http.post(
      `${this.baseUrl}/teacher/courseperstudent`,
      courseperstudent,
      httpOptions
    );
  }
 fetchTeacherCalendar(teacherID: string) {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get(
      `${this.baseUrl}/teacher/calendar?id=${teacherID}`,
      httpOptions
      //,{ withCredentials: true }
    );
  }
  fetchTeacherCalendarTeacher(teacherID: string) {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get(
      `${this.baseUrl}/teacher/calendar-teacher?id=${teacherID}`,
      httpOptions
      //,{ withCredentials: true }
    );
  }
  fetchTeacherCalendarTrainer(teacherID: string) {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get(
      `${this.baseUrl}/teacher/calendar-trainer?id=${teacherID}`,
      httpOptions
      //,{ withCredentials: true }
    );
  }

  fetchVisitsCycleSession(cycle: number, sess: number) {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get(
      `${this.baseUrl}/admin/students_visits_per_cycle_session?cycle=${cycle}&session_num=${sess}`,
      httpOptions
    );
  }

  fetchTeacherCycles(teacherID: string): Observable<TeacherCycles[]> {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get<TeacherCycles[]>(
      `${this.baseUrl}/teacher/cycles_teacher/?teacherID=${teacherID}`,
      httpOptions
    );
  }

    /********************************** update **************************** */	
    fetchTeacherCyclesByFilter(teacherID: string, startDate: String,endDate: String, choosedPath: string, timeSelected: String): Observable<TeacherCycles[]> {	
      console.log('fetchTeacherCyclesByFilter========== timeSelected ==>',timeSelected )	
      const httpOptions = {	
        withCredentials: true,	
      };	
      return this.http.get<TeacherCycles[]>(	
        `${this.baseUrl}/teacher/cycles_teacherByFilter/?teacherID=${teacherID}&startDate=${startDate}&endDate=${endDate}&choosedPath=${choosedPath}&timeSelected=${timeSelected}`  ,	
        httpOptions	
      );	
    }	
    fetchTeacherPath(teacherID: string): Observable<Object[]>{	
     const httpOptions = {	
        withCredentials: true,	
      };	
      return this.http.get<TeacherCycles[]>(	
        `${this.baseUrl}/teacher/pathByTeacher/?teacherID=${teacherID}`,	
        httpOptions	
      );	
    }	
    /**************************************************************************************** */


  fetchCycleStudents(cycle: number): Observable<CycleStudents[]> {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get<CycleStudents[]>(
      `${this.baseUrl}/admin/students_per_cycle?cycle=${cycle}`,
      httpOptions
    );
  }
  //teacher //end
  //*********************************** */

  /* Students //start*/
  //*********************************** */
  /* requests */
  giveMeParticipLink(meeting: number, meeting_details_participant: object) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
      responseType: "text" as "json",
    };
    const body = {
      data: {
        meeting_id: meeting.toString(),
        fname: meeting_details_participant["fname"],
        lname: meeting_details_participant["lname"],
        stud_id: meeting_details_participant["stud_id"],
      },
    };
    return this.http.post(
      `${this.baseUrl}/student/unicko-participant`,
      body,
      httpOptions
    );
  }

  fetchCoursesSessions(userCode: string): Observable<Course[]> {
    const httpOptions = {
      withCredentials: true,
    };
    console.log("the code", userCode);
    return this.http.get<Course[]>(
      `${this.baseUrl}/student/courses?id=${userCode}`,
      httpOptions
    );
  }

  fetchCalendar(userCode: string) {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get(
      `${this.baseUrl}/student/calendar?id=${userCode}`,
      httpOptions
    );
  }

  fetchAttendanceData(id: string, cy: number): Observable<any[]> {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get<any[]>(
      `${this.baseUrl}/admin/student_visits_cyclecourse?id=${id}&cycle=${cy}`,
      httpOptions
    );
  }

  fetchAttendanceDataTotal(id: string): Observable<VisitsTotal[]> {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get<VisitsTotal[]>(
      `${this.baseUrl}/admin/student_visits_cyclecourse_total?id=${id}`,
      httpOptions
    );
  }

  fetchSolaredgeCourses():Observable<Course[]>{
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get<Course[]>(
      `${this.baseUrl}/solaredge/getcourses`,
      httpOptions
    );
  }
  //students //end
  //*********************************** */

  //file manager // start
  //*********************************** */
  downloadFile(id) {
    const httpOptions = {
      withCredentials: true,
      responseType: "blob",
    };
    return this.http.get(`${this.baseUrl}/downloads/file/${id}`, {
      responseType: "blob",
      withCredentials: true,
    });
  }
  deleteFile(id) {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.delete(
      `${this.baseUrl}/uploads/delete/${id}`,
      httpOptions
    );
  }

  uploadFiles(file: File, path: string, query: any) {
    const httpOptions = {
      withCredentials: true,
      params: query,
      reportProgress: true,          // Enable progress reporting
      observe: 'events' as 'body',   // Observe all HTTP events
    };
    
    const data = new FormData();
    data.append("myfile", file, file.name);
  
    return this.http.post(
      `${this.baseUrl}/uploads/files/${path}`,
      data,
      httpOptions
    );
  }
  
  

  // uploadFiles(file: File, path, query) {
  //   const httpOptions = {
  //     withCredentials: true,
  //     params: query,
  //   };
  //   const data = new FormData();
  //   data.append("myfile", file, file.name);
  //   return this.http.post(
  //     `${this.baseUrl}/uploads/files/${path}`,
  //     data,
  //     httpOptions
  //   );
  //   // {params:query}
  // }
  downloadBulck(path, query?) {
    const httpOptions = {
      withCredentials: true,
      params: query,
    };
    return this.http.get(`${this.baseUrl}/downloads/bulk/${path}`, httpOptions);
  }
  getAllFiles(path, query?) {
    const httpOptions = {
      withCredentials: true,
      params: query,
    };
    return this.http.get(
      `${this.baseUrl}/downloads/files/${path}`,
      httpOptions
    );
  }
  //file manager // end
  //*********************************** */

  //notes// start
  //*********************************** */
  removeNote(id) {
    const httpOptions = {
      withCredentials: true,
      params: { id: id },
    };
    return this.http.delete(`${this.baseUrl}/notes/delete-note`, httpOptions);
    // {params:{id:id}}
  }
  updateNote(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
    };
    return this.http.put(
      `${this.baseUrl}/notes/update-note`,
      data,
      httpOptions
    );
  }
  addNewNote(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      withCredentials: true,
      // body: data
    };
    // const body = {...data}
    return this.http.post(`${this.baseUrl}/notes/add-note`, data, httpOptions);
  }
  //notes// end
  //*********************************** */
  getNotes(path) {
    const httpOptions = {
      withCredentials: true,
      params: { path: path },
    };
    return this.http.get(`${this.baseUrl}/notes/get-notes`, httpOptions);
    // {params:{path:path}}
  }

  //misc helpers //start
  //*********************************** */
  checkLink(meeting: string) {
    let opts = new HttpParams();
    opts = opts.append("meeting_id", meeting);
    return this.http.get(`${this.baseUrl}/admin/test-link?`, {
      params: opts,
      responseType: "json",
      withCredentials: true,
    });
  }

  getCoursePath(course) {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get(
      `${this.baseUrl}/admin/course-path-dict/${course}`,
      httpOptions
    );
  }
  //misc helpers //end
  //*********************************** */
  getDocument(name, path) {
    console.log('getDocument');
    
    return this.http.get(`${this.baseUrl}/student/documents/`, {
      responseType: "blob",
      withCredentials: true,
      params: { name: name, path: path },
    });
  }
  buildFreezeForm(obj, request): Observable<Blob> {
    const httpOptions = {
      withCredentials: true,
      data: obj,
      filename: request,
    };
    return this.http.post<Blob>(
      `${this.baseUrl}/student/freeze-status/`,
      httpOptions
    );
  }
  buildNdaForm(obj, request): Observable<Blob> {
    console.log('buildNdaForm - obj:',obj);
    console.log('buildNdaForm - request:',request);
    
    const httpOptions = {
      withCredentials: true,
      data: obj,
      filename: request,
    };
    return this.http.post<Blob>(`${this.baseUrl}/student/nda/`, httpOptions);
  }
  
  buildStudentCert(obj, request): Observable<Blob> {
    const httpOptions = {
      withCredentials: true,
    };
    const myBody = {  
      data: obj,
      filename: request
    }
    return this.http.post<Blob>( `${this.baseUrl}/student/student-cert/`,myBody ,httpOptions );   // <========================student certificate
  }
  buildStudentGraduationCertificate(obj, request): Observable<Blob> {
    const httpOptions = {
      withCredentials: true,
    };
    const myBody = {  
      data: obj,
      filename: request
    }
    return this.http.post<Blob>( `${this.baseUrl}/student/graduation-certificate/`,myBody ,httpOptions );   // <========================student certificate
  }

  getMarkSheet(id: string) {
    return this.http.get(`${this.baseUrl}/student/report-card?id=${id}`, {   //<=============================marksheet 
      withCredentials: true,
    });
  }
  
  getTicketsByDate(startDate: String, endDate: String, status?: '' | 'open' | 'Closed' | 'In-Progress') {
    console.log('startDate===================> api',startDate,endDate)
    let url = `${this.baseUrl}/admin/ticketsByDate/?startDate=${startDate}&endDate=${endDate}`;
    url += status ? `&status=${status}` : '';
    return this.http.get(url, {
      withCredentials: true,
    });
  }   

  getTickets(id: string) {
    return this.http.get(`${this.baseUrl}/admin/tickets?student_id=${id}`, {
      withCredentials: true,
    });
  }
  newTicket(ticket) {
    return this.http.post(`${this.baseUrl}/admin/new-ticket`, ticket, {
      withCredentials: true,
    });
  }
  updateTicketStatus(id: string, status: 'open' | 'Closed' | 'In-Progress') {
    return this.http.patch(`${this.baseUrl}/admin/update-ticket-status`, {id, status}, {
      withCredentials: true,
    });
  }

  newDocTicket(ticketDetails) {
    return this.http.post(
      `${this.baseUrl}/admin/new-doc-ticket`,
      ticketDetails,
      { withCredentials: true }
    );
  }
  // newTechProblemTicket(obj){
  //   return this.http.post(
  //     `http://rt-test.xyz:3000/admin/tech_problems`, obj,{ withCredentials: true}
  //   );
  // }

  newProblemTicket(obj) {
    return this.http.post(`${this.baseUrl}/admin/general_problems`, obj, {
      withCredentials: true,
    });
  }

  newAction(ticketDetails) {
    return this.http.post(
      `${this.baseUrl}/admin/action`,
      { ticketDetails },
      { withCredentials: true }
    );
  }


  /****************************************** unicko-video************************* */
  getRegisteredCourses(id: string): Observable<registeredCourse[]>  {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get<registeredCourse[]>(`${this.baseUrl}/student/registered-courses?id=${id}`, {withCredentials: true });
    // return this.http.get<registeredCourse[]>(`https://194.36.91.109:3042/student/registered-courses?id=${id}`, {withCredentials: true });
  }
  getUnickoVideo(id: string) {
    const httpOptions = {
      withCredentials: true,
      responseType: "blob",
    };
    return this.http.get(`${this.baseUrl}/student/unicko-video/${id}`, {
    // return this.http.get(`https://194.36.91.109:3042/student/unicko-video/${id}`, {
      withCredentials: true,
    });
  }

  getVideoListForCourse(code: string): Observable<VideoData[]> {
    const httpOptions = {
      withCredentials: true,
    };
    return this.http.get<VideoData[]>(`${this.baseUrl}/student/unicko-lecture-list?code=${code}`, { withCredentials: true });
    // return this.http.get<VideoData[]>(`https://194.36.91.109:3042/student/unicko-lecture-list?code=${code}`, { withCredentials: true });
  } 
/****************************************** unicko-video************************* */


   getQuestionnaire(cyclecode:any){   //: Observable<any[]> {
    const httpOptions = {
      withCredentials: true,
    };
    console.log('cyclecode==========>',cyclecode)
      return this.http.get<any[]>(`${this.baseUrl}/student/questionnaire?cyclecode=${cyclecode}`, { withCredentials: true });
    //})
  }
  postQuestionnaireAnswers(ans:any): Observable<any> {
    console.log('questionnaireAnswers',ans)
    return this.http.post(`${this.baseUrl}/student/questionnaireAnswers`, ans, {  withCredentials: true  });          //<==============================questionnaireAnswers
  }

  getSnooze(id: string,cyclecode: number): Observable<any>{
    console.log('cyclecode',cyclecode)
    return this.http.get<any>(`${this.baseUrl}/student/snooze?id=${id}&cyclecode=${cyclecode}`, { withCredentials: true });
  }

  postSnooze(id: string,cyclecode: number): Observable<any> {
    const data  = {id,cyclecode}
    return this.http.post(`${this.baseUrl}/student/postSnooze`, data, {  withCredentials: true  });
  }

  getQuestionnaireCompletion(id: string,cyclecode: number): Observable<any>{
    console.log('cyclecode',cyclecode)
    return this.http.get<any>(`${this.baseUrl}/student/questionnaireCompletion?id=${id}&cyclecode=${cyclecode}`, { withCredentials: true });
  }

  postQuestionnaireCompletion(id: string,cyclecode: number, isComplete: boolean): Observable<any> {
    const data  = {id,cyclecode,isComplete }
    return this.http.post(`${this.baseUrl}/student/questionnaireCompletion`, data, {  withCredentials: true  });
  }

  postStudentLectureAttendanceStart(id: any,cycleCode: any): Observable<any> {
    console.log('postStudentLectureAttendanceStart -service')
    const data  = {id,cycleCode}
    return this.http.post(`${this.baseUrl}/student/lectureAttendanceStart`,data, {  withCredentials: true  });
  }
  sendEmail(data: any){   
    const mail = {
      "to":data.to, 
      "from":data.from, 
      "subject": data.subject,
      "html": data.html ,
      "source" : "RT_Student_Portal"
    }
    return this.http.post(`https://st-portal.online:3057/email`, mail, {
      withCredentials: false,
    });
  }
  sendNDAtoServer(body: any){
    return this.http.post(`${this.baseUrl}/admin/signedNda`, body,   { responseType: 'text' })
    
  }
  sendPlacementToServer(body: any){
    return this.http.post(`${this.baseUrl}/admin/signedPlacement`, body,   { responseType: 'text' })
    
  }
  // boolean function - precentage of finished courses need to be valued as a number in the range of (0,1).
  isStudentFinishedPercentage(studentId: any, RT_Student_Portal: string, percentage: number){
    return this.http.get<any>(`${this.baseUrl}/student/isStudentFinishedPercentage?studentId=${studentId}&source=${RT_Student_Portal}&percentage=${percentage}`, {  withCredentials: true  });
  }
  postStudentCV(data: any){
    // return this.http.post(`${this.baseUrl}/admin/studentCV`, data,   { responseType: 'text' })
    return this.http.post(`${this.baseUrl}/admin/studentCV`, data,  { reportProgress: true, observe: 'events'})
  }
  postScheduleAppointment(data: Object){
    return this.http.post(`${this.baseUrl}/admin/scheduleAppointment`, data)
  }
  postIsNdaSigned(data: Object){
    return this.http.post<{ans:boolean}>(`${this.baseUrl}/student/isNdaSigned`, data)
  }
  postIsPlacementSigned(data: Object){
    return this.http.post<{ans:boolean}>(`${this.baseUrl}/student/isPlacementSigned`, data)
  }
  postGeoLocation(data : {latitude: number, longitude: number} ){
    return this.http.post<{ans:boolean}>(`${this.baseUrl}/student/geoLocation`, data)
  }
  postFileDownloadLog(data : {studentId: string, fileName: string} ){
    const socket_id = localStorage.getItem("socketId");
    console.log('postFileDownloadLog:',socket_id);
    const dataWithSocket = {
      ...data,
      socket_id
    }
    return this.http.post(`${this.baseUrl}/student/fileDownloadLog`, dataWithSocket)
  }
  postVideoWatcherLog(data : {studentId: string, videoId: number} ){
    const socket_id = localStorage.getItem("socketId");
    console.log('postVideoWatcherLog');
    const dataWithSocket = {
      ...data,
      socket_id
    }
    return this.http.post(`${this.baseUrl}/student/videoWatcherLog`, dataWithSocket)
  }
  isGraduationCertificate(student_id: string) {
    return this.http.get<{ans:boolean}>(`${this.baseUrl}/student/isGraduationCertificate/${student_id}`, {
      withCredentials: true,
    });
  }
  resetUnickoTeachersUers(){
    return this.http.get(`${this.baseUrl}/admin/resetUnickoTeachersUers`, {
      withCredentials: true,
    });
  }
}
