import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { User } from "app/models/interfaces/user/user";
import {SocketService} from "app/services/socket.service"

@Injectable({ providedIn: "root" })
export class IsLoggedService {
  private isInternshipSource = new BehaviorSubject<boolean>(false)
  isInternship = this.isInternshipSource.asObservable(); /// is the student allow to signed on the intership.

  // private isInternshipSignedAlreadySourceSource = new BehaviorSubject<boolean>(false)
  // isInternshipSignedAlready = this.isInternshipSignedAlreadySourceSource.asObservable();/// is the student signed on the intership already.

  private isPlacementSource = new BehaviorSubject<boolean>(false)
  isPlacement = this.isPlacementSource.asObservable();
  
  private isPlacementSignedAlreadySource = new BehaviorSubject<boolean>(false)
  isPlacementSignedAlready = this.isPlacementSignedAlreadySource.asObservable();

  private isNdaSignedAlreadySource = new BehaviorSubject<boolean>(false)/// is the student signed on the NDA already.
  isNdaSignedAlready = this.isNdaSignedAlreadySource.asObservable();

  private isGraduationCertificateSource = new BehaviorSubject<boolean>(false)
  isGraduationCertificate = this.isGraduationCertificateSource.asObservable();

  // private updateIsGraduationCertificatetSource = new BehaviorSubject<boolean>(false)
  // updateIsGraduationCertificatet = this.updateIsGraduationCertificatetSource.asObservable();

  // options = {
  //   enableHighAccuracy: true, // Provides a hint to try to get the best possible results
  //   timeout: 5000, // The maximum length of time (in milliseconds) the device is allowed to take in order to return a position
  //   maximumAge: 0 // Indicates that the application wants to receive the most recent location
  // };
  // geoLocationData: {latitude: number, longitude: number}


  constructor() {
    console.log('IsLoggedService was created!');
    
  }
  isUserLoggedIn = new BehaviorSubject(false);
  setUserLoggedIn(loggedIn: boolean) {
    return this.isUserLoggedIn.next(loggedIn);
  }
  userProfile = new BehaviorSubject("Login");
  setUserProfile(profile: string) {
    return this.userProfile.next(profile);
  }
  userData = new BehaviorSubject(null);
  setUserData(userData: User) {
    return this.userData.next(userData);
  }
  getData() {
    return this.userData.asObservable();
  }



  // updateIsInternshipSignedAlready(IsFinished: boolean){ 
  //   this.isInternshipSignedAlreadySourceSource.next(IsFinished)
  // }
  // getisInternshipSignedAlready(){
  //   return this.isInternshipSignedAlready
  // }


  
  updateIsInternship(IsFinished: boolean){
    this.isInternshipSource.next(IsFinished)
  }
  getisInternship(){
    return this.isInternship
  }
  
  updateIsPlacement(isPlacement: boolean){
    return this.isPlacementSource.next(isPlacement)
  }
  getPlacement(){
    return this.isPlacement
  }

  updateIsPlacementSignedAlready(isNdaSignedAlready: boolean){
    return this.isPlacementSignedAlreadySource.next(isNdaSignedAlready)
  }
  
  updateIsNdaSignedAlready(isNdaSignedAlready: boolean){
    return this.isNdaSignedAlreadySource.next(isNdaSignedAlready)
  }
  getIsNdaSignedAlready(){
    return this.isNdaSignedAlready
  }

    updateIsGraduationCertificate(IsGraduation: boolean){
      this.isGraduationCertificateSource.next(IsGraduation)
    }
    getisGraduationCertificate(){
      return this.isGraduationCertificate
    }

  // getGeoLocation(){
  //   navigator.geolocation.getCurrentPosition(this.success, this.error,this.options);
  // }
  // success=(position: any)=>{
  //   const latitude  = position.coords.latitude;
  //   const longitude = position.coords.longitude;
  //   const location  = {latitude, longitude }
  //   this.getData().subscribe((data)=>{
  //     const student_id = data.user.studentID
  //     const dateTime = Date.now()
  //     const userGeoLocationData = {
  //         ...location,
  //         student_id : student_id,
  //         dateTime: dateTime
  //     }
  //    // this.socketService.sendMessage(userGeoLocationData)

  //   })
  // }
  // error=(err: any)=> {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  // }
 
}
