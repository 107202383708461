import { Component, OnInit } from "@angular/core";

/* module imports. */
import { ModalService } from "app/modules/shared/components/modal/modal.service";

/* Service imports. */
import { ServerApiService } from "app/services/server-api.service";
import { StudentDataService } from "app/services/students/student-data.service";

/* model imports. */
import { Attendance } from "app/models/interfaces/attendance/attendance";
import { Course } from "app/models/interfaces/course/course";
import { StudentCalendar } from "app/models/interfaces/student/studentCalendar";
import { VisitsTotal } from "app/models/interfaces/visits/visitsTotal";
import { CourseStatus } from "app/models/classes/courseStatus";

@Component({
  selector: "app-table-list",
  templateUrl: "./table-list.component.html",
  styleUrls: ["./table-list.component.css"],
  providers: [ServerApiService],
})
export class TableListComponent implements OnInit {
  courses: Course[] = [];
  cour: Course;
  courseStatus: CourseStatus = new CourseStatus();
  attendance: Attendance[] = [];
  isAttendance: boolean = false;
  student_id = "";
  student_info;
  toggler: boolean = true;
  courseToShow;
  calendar: StudentCalendar[];
  attendanceTotal: VisitsTotal[];

  constructor(
    private ServerApiService: ServerApiService,
    private modalService: ModalService,
    private StudentDataService: StudentDataService
  ) {}

  ngOnInit() {
    this.loadData();
    this.StudentDataService.studentloadend.subscribe((val) => {
      this.loadData();
      for (let i = 0; i < this.courses.length; i++){
        if(!this.courses[i].update_date){
          this.courses.splice(i, 1);
          i--;
        }
      }
      console.log(' the courses============> :',this.courses);
    });
  }
getParticipants(){
  fetch('https://api.unicko.com/v1/meetings/sessions/{session_id}/participants')
	.then(response => response.json())
	.then(data =>{
      console.log('getParticipants==========>',data)
    })
	.catch(err => console.error(err));
}
  openParticipantUrl(meeting: number, cycleCode: any) {
    this.StudentDataService.giveMeParticipantUrl(
      meeting,
      this.student_info.firstName,
      this.student_info.familyName,
      this.student_id
    );
    console.log('postStudentLectureAttendanceStart')
    this.ServerApiService.postStudentLectureAttendanceStart(this.student_id,cycleCode).subscribe(
      (data: any) => {
        console.log('postStudentLectureAttendanceStart- table-list.compenent 73')
      });
  }

  /**
   * Function to hide the unicko button if the course already end.
   * @param theStatus A string variable that contains the course updating status for database.
   * @returns Boolean value that represent if the course already completed, if yes then the unicko open button will not show up. Otherwise will show.
   */
  isTheCourseEnd(theStatus: string): boolean {
    return this.courseStatus
      .getCourseStatusArray()
      .includes(theStatus.toLocaleLowerCase());
  }

  getAtendanceData(course: Course) {
    this.ServerApiService.fetchAttendanceData(
      this.student_id,
      course.cycle
    ).subscribe(
      (data: Attendance[]) => {
        this.attendance = data;
        this.cour = course;
        console.log(data);
        let modalWidth = Math.floor(document.body.offsetWidth * 0.6);
        let x: number = Math.floor(document.body.offsetWidth * 0.2);
        let y: number = Math.floor(document.body.offsetHeight * 0.15);
        this.openModal("custom-modal-2", x, y, modalWidth);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // getAtendanceData(course: Course) {
  //   return this.ServerApiService.fetchAttendanceData(this.student_id, course.cycle);
  // }
  // modal window
  openModal(id: string, x: number, y: number, l: number) {
    this.isAttendance = true;
    this.modalService.open(id, x, y, l);
  }
  closeModal(id: string) {
    this.modalService.close(id);
    this.isAttendance = false;
  }
  loadData() {
    this.courses = this.StudentDataService.getCourses();
    this.calendar = this.StudentDataService.getCalendar();
    this.attendanceTotal = this.StudentDataService.getAttendenceTotal();
    this.student_info = this.StudentDataService.getStudentInfo();
    this.student_id = this.student_info.studentID;
  }
  getAtt(cycle) {
    const filtered = this.attendanceTotal.filter((course) => {
      return course.cyclecode === cycle;
    });
    return filtered;
  }
}
